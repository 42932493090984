import { Box, Button, CircularProgress, Dialog, DialogContent, Divider, IconButton, Menu, MenuItem, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { AccountCircle, Send, WhatsApp } from "@mui/icons-material";
import SosOutlinedIcon from '@mui/icons-material/SosOutlined';
import SchoolIcon from '@mui/icons-material/School';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import { sendSupportEmail } from "../lib/email/aws";

const UserMenu = () => {
	const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElSupport, setAnchorElSupport] = useState(null);
    const [openLoader, setOpenLoader] = useState(false);
    const [message, setMessage] = useState('');
    const [sending, setSending] = useState(false);
    const [sended, setSended] = useState(false);
    const [feedback, setFeedback] = useState('');

    const navigate = useNavigate();
    const { user, loading, permission, signOut } = useAuth()

	const handleMenu = (e) => {
		setAnchorEl(e.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleMenuSupport = (e) => {
		setAnchorElSupport(e.currentTarget);
	};

	const handleCloseSupport = () => {
		setAnchorElSupport(null);
	};

    const handleSubscription = async (e) => {
        e.preventDefault();
        setOpenLoader(true);
        handleClose();
        const { url } = await fetch('https://us-central1-game-vision-6f49e.cloudfunctions.net/endPoint/account', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: user.email
            }),
        }).then(r => r.json());
                
        if(url){
            window.location.href = url;
        }else{
            setOpenLoader(false);
        }
        
    }

    const handleSupportEmail = async () =>{
        setSending(true);
        try{
            await sendSupportEmail(user.email,message);
            setSending(false);
            setSended(true);
            setFeedback("Enviada com sucesso!");
        }catch{

            setSending(false);
            setSended(true);
            setFeedback("Ocorreu um erro, por favor tente o suporte por WhatsApp.");
        }
    }
        
    return(
        <div>

            {user && !loading && (
                <div>

                    {!permission && (
                        <Button variant="outlined" size="small" onClick={()=>{navigate('/precos')}}>                        
                            Assine o PRO
                        </Button>
                    )}    

                    <IconButton
                        size="large"
                        aria-label="suporte"
                        aria-controls="menu-support"
                        aria-haspopup="true"
                        onClick={(e)=>{handleMenuSupport(e);}}
                        color="inherit"
                    >
                        <SosOutlinedIcon />
                    </IconButton>

                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={(e)=>{handleMenu(e);}}
                        color="inherit"
                    >
                        <AccountCircle />
                    </IconButton>

                    <Menu
                        id="user-menu"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}            
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >	
                        <MenuItem onClick={handleSubscription}>Minha Conta</MenuItem>
                        <Divider/>
                        <MenuItem onClick={()=>{signOut();handleClose();}}>Sair</MenuItem>
                    </Menu>

                    <Menu
                        id="support-menu"
                        anchorEl={anchorElSupport}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}            
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorElSupport)}
                        onClose={handleCloseSupport}
                    >	

                        <Box display="flex" flexDirection="column" paddingX="20px" sx={{mt:2}}>
                            <Typography>
                                Precisa de ajuda ou encontrou algum erro? 
                            </Typography>
                            <Typography>
                                Entre em contato agora mesmo!
                            </Typography>
                        </Box>

                        <Divider sx={{my:3}} />

                        <Box display="flex" flexDirection="column" paddingX="20px">
                            <Typography>
                                Suporte por E-mail
                            </Typography>
                            {!sending && !sended && (
                                <>
                                    <Typography variant="caption">
                                        Descreva detalhadamente o que aconteceu.
                                    </Typography>
                                    <TextField sx={{mt:2}} 
                                        label="Mensagem"
                                        multiline 
                                        rows={3} 
                                        value={message}
                                        onChange={(e)=>{setMessage(e.target.value);}}
                                    />

                                    <Button sx={{my:2}} 
                                        variant="outlined" 
                                        startIcon={<Send/>}
                                        onClick={handleSupportEmail}
                                    >
                                        Enviar Mensagem
                                    </Button>
                                </>
                            )}

                            {sending && !sended && (
                                <CircularProgress />
                            )}

                            {!sending && sended && (
                                <>{feedback}</>
                            )}
                            
                            <Typography variant="caption">
                                Responderemos através do seu e-mail cadastrado.
                            </Typography>
                        </Box>

                        <Divider sx={{my:3}} />

                        <Box display="flex" flexDirection="column" paddingX="20px">
                            
                            <Typography>
                                Suporte por WhatsApp 
                            </Typography>
                            <Typography variant="caption">
                                +55 (11) 93713-9001
                            </Typography>
                            
                            {!window.electron && (
                                <Button sx={{my:2}} 
                                    color="success" 
                                    startIcon={<WhatsApp />} 
                                    variant="outlined"
                                    href="https://api.whatsapp.com/send?phone=5511937139001"
                                    target="_blank"
                                >
                                    WhatsApp
                                </Button>
                            )}
                            
                            <Typography variant="caption">
                                Horário de atendimento: Seg. à Sex 9h00 às 17h00
                            </Typography>

                        </Box>

                        <Divider sx={{my:3}} />
                        
                        <Box display="flex" flexDirection="column" paddingX="20px">
                            
                            <Typography >
                                Consulte a documentação
                            </Typography>
                            <Button sx={{mt:2}} 
                                variant="outlined" 
                                startIcon={<SchoolIcon />} 
                                color="inherit"
                                onClick={()=>{window.location.href = '/documentacao';}}
                            >
                                Documentação
                            </Button>
                            
                            <Button sx={{my:2}} 
                                variant="outlined" 
                                startIcon={<SubscriptionsIcon/>} 
                                color="inherit"
                                onClick={()=>{window.location.href = '/tutorial';}}
                            >
                                Tutoriais
                            </Button>
                            
                        </Box>

                    </Menu>

                </div>
            )}
        
            {!user && !loading && (
                <div>
                    {/*
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={()=>{window.location.href = '/login';}}
                        color="inherit"
                    >
                        <AccountCircle />
                    </IconButton>
                    */}
                    <Button 
                        variant="outlined" 
                        size="small" 
                        onClick={()=>{window.location.href = '/criar-conta';}}
                    >
                        Entrar 
                    </Button>
                </div>
            )}

            <Dialog open={openLoader}>
                <DialogContent sx={{width:'40vw',height:'40vh', display:'flex', justifyContent:'center', alignItems:'center'}} >
                    <CircularProgress />
                </DialogContent>
            </Dialog>

        </div>
    )
}

export default UserMenu;